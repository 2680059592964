define([
    "ksc-ubo",
    "ksc-breakpoint-notifier",
], function (kscUbo,
             breakpointNotifier) {
    function LayoutViewModel(initObject, when) {
        var self = this;

        this.lockScrollOnBodySm = ko.observable(false);
        this.lockScrollOnBodyXs = ko.observable(false);
        this.lockScrollOnBody = ko.observable(false);
        this.megaMobileNavOpen = ko.observable(false);

        var isXs = ko.observable(false);
        var isSm = ko.observable(false);

        self.disableScrolling = ko.pureComputed(function () {
            return (isXs() && self.lockScrollOnBodyXs())
                || (isSm() && self.lockScrollOnBodySm())
                || self.lockScrollOnBody();
        });

        self.setPosition = ko.computed(function () {
            if (!self.disableScrolling() && self.lastPosition) {
                window.scrollTo(0, self.lastPosition);
                self.lastPosition = false;
            }
        })

        when(kscUbo.events.uboOpened).do(function () {
            self.lastPosition = window.scrollY;
            self.lockScrollOnBodyXs(true);
        });

        when(kscUbo.events.uboClosed).do(function () {
            self.lockScrollOnBodyXs(false);
        });
        
        when(breakpointNotifier.events.isXs).do(function (value) {
            isXs(value);
        });

        when(breakpointNotifier.events.isSm).do(function (value) {
            isSm(value);
        });
    }

    return LayoutViewModel;
});